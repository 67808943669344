import React, { useEffect, useState } from 'react';
import MetaTag from '@/components/ui/MetaTag';
import Categories from './partials/Categories';
import HomeSlider from './partials/HomeSlider';
import NewProduct from './partials/NewProducts';

import ArticlesPartial from './partials/Articles';
import NewLive from './partials/NewLive';
import { useTranslation } from 'next-i18next';
import { CategoryType, HomeConstantType, ImageSlider } from './const';
import { Button, Modal, ModalBody } from '@/components';
import { toPhoneNumberNational } from '@/libs/app';
import { ReferrerType } from '@/features/auth/login-by-password';
import { useMasterData } from '@/contexts/profile';
import { MasterDataModel } from '@/models';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

export type HomePageProps = {
  sliders: ImageSlider[];
  categories: CategoryType[];
};
type ReferralType = {
  isShow: boolean;
  referrer: ReferrerType | null;
};
const HomePageLayout = ({ sliders, categories }: HomePageProps) => {
  const ReferralDefault = {
    isShow: false,
    referrer: null,
  };
  const masterData: MasterDataModel = useMasterData();
  const { theme } = masterData ?? {};
  const { web_bg_livestream_news_url } = theme ?? {};


  const { t } = useTranslation('layout');
  const [data, setData] = useState<ReferralType>(ReferralDefault);
  useEffect(() => {
    let data = localStorage.getItem('referrer');
    if (data) {
      setData(JSON.parse(data));
      localStorage.removeItem('referrer');
    }
  }, []);
  const closeModal = () => {
    setData(ReferralDefault);
  };

  if (!masterData) return <></>;

  return (
    <>
      <MetaTag
        title={masterData?.company_info?.title || 'An Thư The Diamond Store'}
        description={masterData?.company_info?.description || 'An Thư The Diamond Store'}
        image={`${process.env.APP_URL}/logo-bg.png`}
      />
      <h1 className="tw-hidden">An Thư The Diamond Store</h1>
      {!!sliders.length && (
        <section className="index-slider">
          <HomeSlider images={sliders} />
        </section>
      )}

      <section className="tw-py-6 md:tw-py-10">
        {categories?.length > 0 && <Categories categories={categories} />}
      </section>
      {masterData?.categories_home && masterData?.categories_home[0] && (
        <section className="container tw-mx-auto">
          <NewProduct
            isSale={masterData?.categories_home[0].isSale}
            title={masterData?.categories_home[0].title}
            catCode={masterData?.categories_home[0].code}
            theme={theme}
            t={t}
          />
        </section>
      )}

      <NewLive title="Trực tiếp" theme={theme} t={t} />
      {masterData?.categories_home && masterData?.categories_home[1] && (
        <section className="container tw-mx-auto tw-mt-8">
          <NewProduct
            isSale={masterData?.categories_home[1].isSale}
            title={masterData?.categories_home[1].title}
            catCode={masterData?.categories_home[1].code}
            theme={theme}
            t={t}
          />
        </section>
      )}
      {masterData?.categories_home && masterData?.categories_home[2] && (
        <section className="container tw-mx-auto tw-mt-8">
          <NewProduct
            isSale={masterData?.categories_home[2].isSale}
            title={masterData?.categories_home[2].title}
            catCode={masterData?.categories_home[2].code}
            theme={theme}
            t={t}
          />
        </section>
      )}
      {masterData?.menu_blogs[0].id && (
        <section
          className="tw-w-full tw-mt-12"
          style={{
            backgroundImage: web_bg_livestream_news_url
              ? `url(${web_bg_livestream_news_url})`
              : 'url("/bg-live.svg")',
          }}
        >
          <div className="article container tw-pt-10 tw-mx-auto md:tw-pb-12">
            <ArticlesPartial
              title={t('main.news')}
              t={t}
              id={masterData?.menu_blogs[0].id}
              slug={masterData?.menu_blogs[0].slug}
              theme={theme}
            />
          </div>
        </section>
      )}

      {data.isShow && data.referrer && (
        <Modal>
          <ModalBody>
            <div className="tw-font-semibold">Thông báo chúc mừng</div>
            <p className="tw-mt-2">
              Chúc mừng bạn đã tạo thành công tài khoản mới theo lời giới thiệu của {data.referrer?.fullname} -{' '}
              {toPhoneNumberNational(data.referrer?.phone, data.referrer?.country, true)}
            </p>
            <Button
              className="tw-bg-primary-main tw-text-white tw-text-lg tw-mt-8 !tw-rounded-full"
              onClick={() => closeModal()}
            >
              OK
            </Button>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default HomePageLayout;
