import { CategoryModel } from '@/models';
import { QueryKey } from '@/layouts/shared';
import instance from '@/libs/axios';
import axios, { instanceNews, AxiosResponse, AxiosErrorResponse } from '@/libs/axios';
import { QueryObserverOptions, useQuery } from 'react-query';
import { Product } from '../products/shared';
interface ParamsPayload {
  page?: number;
  per_page?: number;
  categories?: number;
  slug?: string;
  offset?: number;
}
export const fetchMasterData = () => {
  return axios.get('/api/v1/masterdata/list');
};
export const fetchMasterDataByKey = (key: string) => {
  return axios.get(`/api/v1/masterdata/${key}`);
};
export const fetchBanners = () => {
  return axios.get('/api/v1/banner', { headers: { 'Content-Type': 'application/json; charset=utf-8' } });
};

export const fetchNews = (params: string | ParamsPayload) => {
  return instanceNews.get('/wp-json/wp/v2/posts', { params });
};

export const fetchCategoryProducts = (code: string) => instance.get(`/api/v1/products/category/${code}/list`);
export const useFetchNewsQuery = (
  payload: ParamsPayload,
  options?: QueryObserverOptions<AxiosResponse<any>, AxiosErrorResponse, any, AxiosResponse<any>, (string | any)[]>
) => {
  return useQuery(
    [QueryKey.News, payload],
    ({ queryKey }) => {
      const [, params] = queryKey;
      return fetchNews(params);
    },
    options
  );
};

export interface Category extends CategoryModel {
  products: Product[];
}
export const useFetchCategoryProducts = (
  code: string,
  options?: QueryObserverOptions<AxiosResponse<Category>, AxiosErrorResponse, any, AxiosResponse<any>, (string | any)[]>
) => useQuery([QueryKey.ProductCategory, code], () => fetchCategoryProducts(code), options);
