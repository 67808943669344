import React, { memo, useEffect, useState } from 'react';
import { HomeSliderProps } from '../../const';
import { Image, Link } from '@/components';
const HomeSlider = ({ images }: HomeSliderProps) => {
  const [slideIndex, setSlideIndex] = useState<number>(1);
  useEffect(() => {
    const interval = setInterval(() => {
      if (slideIndex < images.length) {
        setSlideIndex((slideIndex) => slideIndex + 1);
      } else if (slideIndex === images.length) {
        setSlideIndex(1);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [slideIndex, images]);
  const moveDot = (index: number) => {
    setSlideIndex(index);
  };
  const items = Array.isArray(images) ? images : [];
  return (
    <>
      <div className="tw-w-full tw-h-full tw-relative tw-flex tw-pb-40-percent md:tw-pb-35-percent">
        <div className="tw-w-full tw-h-full home-slider tw-relative">
          {items.map((item, index) => {
            return (
              <Link
                key={item.title + '-' + index}
                className={`tw-w-full tw-bg-center tw-bg-cover tw-absolute tw-top-0 tw-left-0 tw-bottom-0  tw-pb-40-percent md:tw-pb-35-percent ${
                  slideIndex === index + 1 ? 'slider-item active' : 'slider-item '
                }`}
                url={images[slideIndex - 1]?.link ?? ''}
              > 
                <Image
                  src={item.image_url}
                  alt={item.title}
                  priority={slideIndex === index + 1 ? true : false}
                  className="tw-w-full tw-h-full !tw-absolute tw-top-0 tw-left-0"
                />
              </Link>
            );
          })}
        </div>

        <div className="tw-absolute slider-index-dots tw-gap-x-2 md:tw-gap-x-3 tw-flex">
          {items.map((item, index) => (
            <div
              onClick={() => moveDot(index + 1)}
              key={item.title + '-' + index}
              className={`tw-w-2 tw-h-2 md:tw-w-4 md:tw-h-4 tw-rounded-full ${
                slideIndex === index + 1 ? 'tw-bg-primary-main' : 'tw-bg-white'
              }`}
            ></div>
          ))}
        </div>
      </div>
    </>
  );
};

export default memo(HomeSlider);
