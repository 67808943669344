import React from 'react';

import withAuthServerSideProps from '@/libs/auth';
import { GetServerSidePropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { KeyActive, PageLayout, PagePath } from '@/layouts/shared';
import { fetchCategories } from '@/features/products/list/api';
import HomePageLayout from '@/features/home';
import { fetchBanners, fetchMasterDataByKey } from '@/features/home/api';
import { HomePageProps } from '@/features/home/layout';
import { getSelectorsByUserAgent } from 'react-device-detect';

const HomePage = ({ sliders, categories }: HomePageProps) => {
  return <HomePageLayout sliders={sliders} categories={categories} />;
};

export const getServerSideProps = withAuthServerSideProps(
  async ({ locale, req, res }: GetServerSidePropsContext) => {
    try {
      const { data: sliders } = await fetchBanners();
      const { data: categoriesData } = await fetchCategories();
      const categories = (Array.isArray(categoriesData) ? categoriesData : []).sort(
        ({ sort: a }, { sort: b }) => a - b
      );
      const { isMobile } = getSelectorsByUserAgent(req.headers['user-agent'] as string);
      return {
        props: {
          categories,
          sliders,
          layout: PageLayout.New,
          keyActive: KeyActive.Home,
          isMobile,
          ...(await serverSideTranslations(locale as string, ['product', 'layout'])),
        },
      };
    } catch (_) {
      return {
        redirect: {
          permanent: false,
          destination: PagePath.DashboardPage,
        },
      };
    }
  },
  { isAntiAuth: false, isPublic: true }
);
export default HomePage;
