import React, { memo, useRef, useState } from 'react';
import { Image, Link, ArrowLeftIcon, Icon } from '@/components';
import { PagePath } from '@/layouts/shared';
import { CategoryPartialsProps } from '../../const';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation } from 'swiper';

const ListCategories = ({ categories }: CategoryPartialsProps) => {
  const listCategories = categories.filter((c) => c.images && !c.hide_menu);
  const swiperRef = useRef<any>();

  return (
    <div className="container tw-flex tw-justify-center tw-relative">
      <button
        className="custom-prev tw-hidden tw-w-10 tw-h-10 tw-rounded-full tw-absolute tw-top-1/4 tw-left-0 md:tw-flex tw-items-center tw-justify-center tw-z-10 tw-text-primary-main"
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <Icon className="tw-w-full tw-h-full" icon={<ArrowLeftIcon />} />
      </button>

      <div className="tw-w-11/12">
        <Swiper
          slidesPerView={7}
          spaceBetween={16}
          keyboard={{
            enabled: true,
          }}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            0: {
              slidesPerView: 3.5,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 5,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 7,
              spaceBetween: 16,
            },
          }}
          // loop={true}
          // navigation={false}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[EffectFade, Navigation]}
          className="product-loop-swiper tw-overflow-auto category-scrollbar"
          id="swiper"
        >
          {listCategories.map((item, key) => (
            <SwiperSlide
              key={`item-${key}`}
              className="tw-relative tw-pb-2 tw-overflow-auto after:tw-absolute after:tw-w-8/12 after:tw-h-[2px] after:tw-bg-primary-main after:tw-bottom-0 after:tw-left-1/2 after:tw--translate-x-1/2"
            >
              <Link
                url={{ pathname: PagePath.CategoryPage, query: { id: item.code } }}
                className="tw-flex tw-justify-center tw-items-center tw-flex-col  tw-space-y-2 "
              >
                <div className="tw-w-[60px] tw-h-[60px] lg:tw-w-[100px] lg:tw-h-[100px] tw-rounded-full tw-overflow-hidden ">
                  <Image
                    src={item.images?.url || ''}
                    className="tw-w-full tw-h-full"
                    alt={item.product_category_name}
                  />
                </div>
                <div className="tw-text-sm md:tw-text-md tw-line-clamp-1 tw-w-full tw-text-center tw-mt-3 tw-text-secondary-70">
                  {item.product_category_name}
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <button
        className="custom-next tw-hidden  tw-w-10 tw-h-10 tw-absolute tw-right-0 tw-top-1/4   tw-text-primary-main md:tw-flex tw-items-center tw-justify-center tw-rotate-180 tw-z-10"
        onClick={() => swiperRef.current?.slideNext()}
      >
        <Icon className="tw-w-full tw-h-full" icon={<ArrowLeftIcon />} />
      </button>
    </div>
  );
};

export default memo(ListCategories);
