import React from 'react';

export type SkeletonLoadingProps = {
  className: string;
};
const SkeletonLoading = ({ className }: SkeletonLoadingProps) => {
  return <div className={`${className} skeleton`}></div>;
};

export default SkeletonLoading;
